/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */
import { css, jsx } from '@emotion/react'
import { Component, Fragment, type RefObject } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem, EuiSkipLink } from '@elastic/eui'

import type { DeploymentGetResponse } from '@modules/cloud-api/v1/types'
import type { CloudAppName, ProfileState, Theme } from '@modules/ui-types'
import { BreadcrumbsContainer } from '@modules/cui/Breadcrumbs'
import type { WithPermissionsProps } from '@modules/permissions-components/PermissionsGate/withPermissions'
import TrialHeaderBadge from '@modules/trial-components/HeaderBadge'
import { WebAuthnFirstTourStep } from '@modules/mfa-management/WebAuthnTourSteps'

import DriftChat from '@/apps/userconsole/components/DriftChat'
import { DriftChatHeaderMenuItem } from '@/apps/userconsole/components/DriftChat/DriftChatHeaderMenuItem'

import SearchAnythingBox from '../SearchAnythingBox'

import HeaderNavigation from './HeaderNavigation'
import Help from './Help'
import UserMenu from './UserMenu'

import type { WrappedComponentProps } from 'react-intl'

export interface ChromeHeaderProps
  extends WithPermissionsProps<
    'hasSearchAllocatorPermission' | 'hasCreateBillingOrganizationPermission'
  > {
  loggedIn: boolean
  hasUserSettings: boolean
  theme: Theme
  appName: CloudAppName
  profile: ProfileState
  setTheme: (theme: Theme) => void
  hideTrialIndicator?: boolean
  showHelp?: boolean
  getStackDeploymentById: (id: string) => DeploymentGetResponse | undefined
  isHeroku: boolean
  cloudPortalEnabled: boolean
  showBreadcrumbs?: boolean
  showOrganization: boolean
  username: string | null
  isDriftEnabled?: boolean
  showDrift?: true
  breadcrumbsRef?: RefObject<HTMLDivElement> | null
  announcementsRef?: RefObject<HTMLDivElement> | null
}

interface State {
  isUserMenuOpen: boolean
}

/* !IMPORTANT: this component renders for logged out users too,
 * for example when the "Not Found" page renders for them.
 * As such, any components that render in here should be "logged in aware"
 * You can check whether the user is logged in via code like this:
 *
 * ```
 * const loggedIn: boolean = hasUnexpiredSession(state)
 * ```
 *
 * `hasUnexpiredSession` is exported from `lib/auth`.
 */
class ChromeHeader extends Component<ChromeHeaderProps & WrappedComponentProps, State> {
  state: State = {
    isUserMenuOpen: false,
  }

  render() {
    const {
      username,
      cloudPortalEnabled,
      hasUserSettings,
      loggedIn,
      setTheme,
      theme,
      showOrganization,
      permissions,
      showBreadcrumbs = true,
      announcementsRef = null,
      breadcrumbsRef = null,
    } = this.props

    const searchStyle = css`
      .chromeHeader-search {
        display: flex;
        align-items: center;
        height: 48px;
        margin: 0 12px;
        width: 100%;

        .euiFormControlLayout {
          transition: width 0.2s ease-in-out;
          width: 40px;
        }

        .euiFieldText--withIcon {
          padding-left: 28px;
        }

        .euiIcon {
          pointer-events: none;
        }

        .euiFieldText {
          width: 100%;
        }
      }
      .chromeHeader-searchCollapsed {
        text-align: center;

        // we need both because: svg icon is hard to style, but input needs override as well
        .euiFormControlLayout,
        .euiFieldText {
          cursor: pointer;
        }

        /* center the search icon while we have a minimized input */
        [class*='euiFormControlLayout-compressed'] .euiFormControlLayoutIcons {
          left: 12px;
        }
      }

      .chromeHeader-searchExpanded {
        .euiFormControlLayout {
          width: 24rem;
        }

        .euiFieldText--withIcon {
          padding-left: 40px;
        }
      }
    `

    return (
      <header data-test-id='chrome-header'>
        <EuiHeader data-test-id='page-header' theme='dark' tabIndex={-1} css={searchStyle}>
          <EuiHeaderSection>
            {showBreadcrumbs && (
              <div data-test-id='announcements-breadcrumbs' ref={announcementsRef} />
            )}
          </EuiHeaderSection>
          <EuiHeaderSection>
            <EuiHeaderSectionItem>
              <EuiSkipLink
                position='fixed'
                destinationId='cloudPortalPage'
                overrideLinkBehavior={true}
              >
                <FormattedMessage
                  id='cloud-header-nav.skip-links'
                  defaultMessage='Skip to main content'
                />
              </EuiSkipLink>
            </EuiHeaderSectionItem>
          </EuiHeaderSection>
          <HeaderNavigation />
          <EuiHeaderSection side='right'>
            {this.renderLoggedInSections()}
            <EuiHeaderSectionItem>
              <UserMenu
                username={username}
                cloudPortalEnabled={cloudPortalEnabled}
                hasUserSettings={hasUserSettings}
                loggedIn={loggedIn}
                setTheme={setTheme}
                showOrganization={showOrganization}
                theme={theme}
                permissions={permissions}
              />
            </EuiHeaderSectionItem>
          </EuiHeaderSection>
        </EuiHeader>

        {showBreadcrumbs && <BreadcrumbsContainer ref={breadcrumbsRef} />}

        <WebAuthnFirstTourStep
          isUserMenuOpen={this.state.isUserMenuOpen}
          loggedIn={this.props.loggedIn}
        />
      </header>
    )
  }

  renderLoggedInSections() {
    const {
      appName,
      hideTrialIndicator,
      loggedIn,
      showHelp,
      showDrift,
      isDriftEnabled,
      isHeroku,
      permissions: { hasSearchAllocatorPermission },
    } = this.props

    const showSearch = appName === 'adminconsole'
    const showTrialInfo = appName === 'userconsole' && !isHeroku

    if (!loggedIn) {
      return null
    }

    return (
      <Fragment>
        {showSearch && hasSearchAllocatorPermission && (
          <EuiHeaderSectionItem>
            <div data-test-id='headerSearchBar'>
              <SearchAnythingBox onBeforeExpand={() => this.closeUserMenu()} />
            </div>
          </EuiHeaderSectionItem>
        )}

        {showTrialInfo && !hideTrialIndicator && (
          <EuiHeaderSectionItem css={css({ alignItems: 'center' })}>
            <TrialHeaderBadge />
          </EuiHeaderSectionItem>
        )}

        {showDrift && isDriftEnabled && (
          <EuiHeaderSectionItem>
            <DriftChat>{DriftChatHeaderMenuItem}</DriftChat>
          </EuiHeaderSectionItem>
        )}

        {showHelp && (
          <EuiHeaderSectionItem>
            <Help />
          </EuiHeaderSectionItem>
        )}
      </Fragment>
    )
  }

  toggleUserPopover() {
    const { isUserMenuOpen } = this.state
    this.setState({ isUserMenuOpen: !isUserMenuOpen })
  }

  closeUserMenu() {
    this.setState({ isUserMenuOpen: false })
  }
}

export default injectIntl(ChromeHeader)
