/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { HIDE_DRIFT_CHAT, SHOW_DRIFT_CHAT, TOGGLE_DRIFT_CHAT } from '../reducers'

import type { UserconsoleState } from '../../../reducers'

export function useDriftChat() {
  const isChatOpen = useSelector<UserconsoleState>(({ driftChat }) => driftChat.isChatOpen)

  const dispatch = useDispatch()

  const hideChat = useCallback(() => dispatch({ type: HIDE_DRIFT_CHAT }), [dispatch])

  const showChat = useCallback(() => dispatch({ type: SHOW_DRIFT_CHAT }), [dispatch])

  const toggleChat = useCallback(() => dispatch({ type: TOGGLE_DRIFT_CHAT }), [dispatch])

  return { isChatOpen, hideChat, showChat, toggleChat }
}
