/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { useCallback, useEffect, useState } from 'react'

import type { CSSProperties } from 'react'

type ChatIframeProps = {
  styles?: CSSProperties
  handleWindowMessage: (event: MessageEvent, chatIframe: HTMLIFrameElement) => void
  chatUrl: string
}

const ChatIframe = ({ chatUrl, handleWindowMessage, styles }: ChatIframeProps) => {
  const initialStyle: CSSProperties = {
    position: 'fixed' as const,
    bottom: '30px',
    right: '30px',
    height: 0,
    width: 0,
    display: 'block',
    zIndex: -1,
    colorScheme: 'light',
  }
  const [chatIframe, setChatIframe] = useState<HTMLIFrameElement | null>(null)
  const onRefChange = useCallback((node: HTMLIFrameElement) => {
    setChatIframe(node)
  }, [])

  useEffect(() => {
    const handleMessage = (event: MessageEvent): void => {
      if (!chatIframe) {
        return
      }

      handleWindowMessage(event, chatIframe)
    }

    if (!chatIframe) {
      return
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [chatIframe, handleWindowMessage, chatUrl])

  return (
    <iframe
      data-test-id='drift-chat'
      style={{ ...initialStyle, ...styles }}
      ref={onRefChange}
      src={chatUrl}
    />
  )
}

export default ChatIframe
