/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useEffect, useState } from 'react'
import { camelCase } from 'lodash'

import { useEuiTheme } from '@elastic/eui'

import { useSessionStorageBackedState } from '@modules/utils/hooks/useLocalStorage'

import ChatIframe from '@/apps/userconsole/components/DriftChat/ChatIframe'

import { getContextAndAttributes, postMessage, startTrackingUserAttributes } from './lib/functions'
import { useDriftChat } from './lib/hooks'
import { DRIFT_PLAYBOOK_FIRED } from './constants'

import type { CSSProperties, FC } from 'react'
import type { AllProps } from './types'

type Props = Exclude<AllProps, 'profile'> & {
  profile: Exclude<AllProps['profile'], null> // require by the time this is rendered

  /** the chat widget is ready */
  onReady?: (chatApi: ChatApi) => void

  /** invoked when the playbook is fired */
  onPlaybookFired?: () => void

  /** styles set from outside, takes precedence over what an iframe sets */
  styles?: CSSProperties
}

export interface ChatApi {
  show: () => void
  hide: () => void
  toggle: () => void
}

const DriftChatIframe: FC<Props> = ({
  chatUrl,
  fetchDriftJwtRequestState,
  profile,
  styles,
  fetchDriftJwt,
  onReady,
  onPlaybookFired,
}) => {
  const {
    euiTheme: {
      levels: { maskBelowHeader },
    },
  } = useEuiTheme()

  const { isChatOpen, hideChat, showChat, toggleChat } = useDriftChat()

  const [chatIframe, setChatIframe] = useState<HTMLIFrameElement | null>(null)

  const [innerStyles, setInnerStyles] = useState<CSSProperties>({})

  const [hasPlaybookFiredOnce, setPlaybookFiredOnce] = useSessionStorageBackedState<boolean>(
    DRIFT_PLAYBOOK_FIRED,
    false,
  )

  useEffect(() => {
    fetchDriftJwt()
  }, [fetchDriftJwt])

  useEffect(() => {
    if (isChatOpen) {
      postMessage(chatIframe, `driftShow`)

      postMessage(chatIframe, `driftOpenChat`)
    } else {
      postMessage(chatIframe, `driftHide`)
    }
  }, [chatIframe, isChatOpen])

  const chatApi: ChatApi = {
    show: () => {
      showChat()
    },
    hide: () => {
      hideChat()
    },
    toggle: () => {
      toggleChat()
    },
  }

  if (fetchDriftJwtRequestState.inProgress) {
    return null
  }

  const mergedStyles = {
    ...innerStyles,
    // override set z-index
    zIndex: maskBelowHeader,
    // override with styles from outside
    ...styles,
  }

  return (
    <ChatIframe chatUrl={chatUrl} handleWindowMessage={handleWindowMessage} styles={mergedStyles} />
  )

  function handleWindowMessage(event: MessageEvent, sourceChatIframe: HTMLIFrameElement): void {
    if (!sourceChatIframe.contentWindow || event.source !== sourceChatIframe.contentWindow) {
      return
    }

    setChatIframe(sourceChatIframe)

    const message = event.data

    switch (message.type) {
      case 'driftWidgetReady': {
        chatApi.hide()

        startTrackingUserAttributes(sourceChatIframe, profile)

        onReady?.(chatApi)

        if (hasPlaybookFiredOnce) {
          onPlaybookFired?.()
        }

        break
      }

      case 'driftPlaybookFired': {
        onPlaybookFired?.()
        setPlaybookFiredOnce(true)
        break
      }

      case 'driftChatClosed': {
        chatApi.hide()

        break
      }

      case 'driftIframeReady': {
        const { context, userAttributes } = getContextAndAttributes(profile)
        const userData = { ...userAttributes, jwt: profile.driftJwt }
        postMessage(sourceChatIframe, `driftSetContext`, { context, user: userData })
        break
      }

      case 'driftIframeResize': {
        const incomingStyles = message.data.styles || ({} as CSSProperties)
        // camelize to avoid style warnings from react
        const camelStyles = Object.keys(incomingStyles).reduce((acc, key) => {
          acc[camelCase(key)] = incomingStyles[key]
          return acc
        }, {} as Record<string, string>) as CSSProperties

        setInnerStyles({
          ...innerStyles,
          ...camelStyles,
        })

        break
      }

      default:
        break
    }
  }
}

export default DriftChatIframe
