/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'

import { EuiButton, EuiButtonEmpty, EuiText, EuiToolTip, EuiTourStep } from '@elastic/eui'

import { type StackDeployment } from '@modules/ui-types'
import { useEbtContext } from '@modules/ebt/EbtContext'
import { useGetVersionCheckStatus } from '@modules/autoops-lib/hooks/useGetVersionCheckStatus'
import useEbtAutoOps from '@modules/autoops-lib/useEbtAutoOps'
import { useLocalStorage } from '@modules/utils/hooks/useLocalStorage'
import LocalStorageKey from '@modules/utils/localStorageKeys'
import { useConfig } from '@modules/cui/ConfigContext'
import { generateAutoOpsUrl } from '@modules/autoops-lib/util'

const messages = defineMessages({
  dismiss: {
    id: 'autoops-tour-text-dismiss',
    defaultMessage: 'Dismiss',
  },
  versionError: {
    id: 'deployment-admin-actions.auto-ops.not-available',
    defaultMessage: 'AutoOps is available only for supported Elastic Stack versions.',
  },
  notConnectedError: {
    id: 'deployment-admin-actions.auto-ops.not-connected',
    defaultMessage:
      'AutoOps is available only in supported regions and will be active within 30 minutes of creating a new deployment.',
  },
  buttonText: {
    id: 'deployment-admin-actions.auto-ops',
    defaultMessage: 'Open AutoOps',
  },
  tourText: {
    id: 'autoops-tour-text',
    defaultMessage: 'AutoOps is enabled on this deployment 🎉',
  },
  new: {
    id: 'autoops-tour-text-new',
    defaultMessage: 'NEW',
  },
})

export const AutoOpsButton = ({ deployment }: { deployment: StackDeployment }) => {
  const checkStatus = useGetVersionCheckStatus(deployment)
  const { formatMessage } = useIntl()
  const { analytics } = useEbtContext()
  const { reportEbtEvent } = useEbtAutoOps()
  const [isTourSeen, setIsTourSeen] = useLocalStorage(LocalStorageKey.autoOpsTourStorageKey, false)
  const markTourAsSeen = () => setIsTourSeen(true)
  const {
    metadata,
    resources: { elasticsearch },
    settings,
  } = deployment
  const isConnected = settings?.auto_ops.status === `connected`
  const isECE = useConfig('APP_PLATFORM') === 'ece'

  if (!elasticsearch[0] || metadata == null || metadata.organization_id == null) {
    return null
  }

  if (isECE) {
    return null
  }

  if (checkStatus === 'loading') {
    return <EuiButton isLoading={true} aria-label='Loading AutoOps button' />
  }

  if (checkStatus === 'disabled') {
    return (
      <EuiToolTip content={formatMessage(messages.versionError)}>
        <EuiButton isDisabled={true}>{formatMessage(messages.buttonText)}</EuiButton>
      </EuiToolTip>
    )
  }

  if (!isConnected) {
    return (
      <EuiToolTip content={formatMessage(messages.notConnectedError)}>
        <EuiButton isDisabled={true}>{formatMessage(messages.buttonText)}</EuiButton>
      </EuiToolTip>
    )
  }

  const href = generateAutoOpsUrl({
    region: elasticsearch[0].region,
    organizationId: metadata.organization_id,
    deploymentId: deployment.id,
  })

  return (
    <EuiTourStep
      content={
        <EuiText size='m' color='subdued' data-test-subj='infra-AutoopsTour-text'>
          {formatMessage(messages.tourText)}
        </EuiText>
      }
      isStepOpen={!isTourSeen}
      maxWidth={350}
      onFinish={markTourAsSeen}
      step={1}
      stepsTotal={1}
      title={formatMessage(messages.new)}
      anchorPosition='downCenter'
      footerAction={
        <EuiButtonEmpty
          data-test-subj='infra-AutoopsTour-dismiss'
          size='s'
          color='text'
          onClick={markTourAsSeen}
        >
          {formatMessage(messages.dismiss)}
        </EuiButtonEmpty>
      }
    >
      <EuiButton
        onClick={(e) => {
          if (analytics) {
            e.preventDefault()
            reportEbtEvent(`autoOpsButtonClicked`, { deployment: deployment.id })
            analytics.flush().then(() => window.location.replace(href))
          }
        }}
        href={href}
        data-test-id='deployment-launch-auto-ops'
      >
        {formatMessage(messages.buttonText)}
      </EuiButton>
    </EuiTourStep>
  )
}
