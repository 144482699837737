/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useContext, useEffect, useState } from 'react'

import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'
import { hasStrongMfaDevice } from '@modules/mfa-lib/devices'
import type { AuthContextData } from '@modules/auth/context'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import AuthContext from '@modules/auth/context'
import { useConfig } from '@modules/cui/ConfigContext'
import Feature from '@modules/utils/feature'
import type { DeviceType } from '@modules/mfa-management/types'
import { useProfile } from '@modules/profile-lib/hooks'
import type { UserProfileFromApi } from '@modules/ui-types'

export function useIsMfaEnforced({
  devices,
}: {
  devices: SaasAuthMfaDeviceResponse[] | undefined
}): boolean | null {
  const ldEnabled = Boolean(useConfig(Feature.launchdarklyId))
  // for environments where LaunchDarkly is disabled (e.g. GovCloud), we don't want to enforce MFA
  const defaultIsMfaEnforced = ldEnabled ? null : false
  const [isMfaEnforced, setIsMfaEnforced] = useState<boolean | null>(defaultIsMfaEnforced)
  const [isFlagsUsable, { mfaEnforced: flag }] = useFlagsWhenLoaded()
  const profile = useProfile()

  const {
    authContext: { method: authMethod },
  } = useContext(AuthContext)

  useEffect(() => {
    if (!isFlagsUsable) {
      return
    }

    if (isMfaEnforced === null) {
      const meetsRequirements = meetsMfaRequirements({
        profile,
        mfaEnforced: flag,
        authMethod,
        devices,
      })

      if (meetsRequirements !== null) {
        setIsMfaEnforced(!meetsRequirements)
      }
    }
  }, [isFlagsUsable, profile, isMfaEnforced, flag, authMethod, devices])

  return isMfaEnforced
}

export function isMfaEnforceable({
  profile,
  mfaEnforced,
  authMethod,
}: {
  profile: UserProfileFromApi | undefined
  mfaEnforced: boolean
  authMethod: AuthContextData['method']
}): boolean {
  if (!profile?.mfa_enforced) {
    return false // mfaEnforced from user API
  }

  if (!mfaEnforced) {
    return false // feature flag
  }

  if (authMethod !== 'username-and-password') {
    return false // only enforce if they logged in with traditional credentials instead of e.g. a third party IdP.
  }

  return true
}

export function meetsMfaRequirements({
  profile,
  mfaEnforced,
  authMethod,
  devices,
}: {
  profile: UserProfileFromApi | undefined
  mfaEnforced: boolean
  authMethod: AuthContextData['method']
  devices: SaasAuthMfaDeviceResponse[] | undefined
}): boolean | null {
  if (!profile) {
    return null
  }

  if (!devices) {
    return null
  }

  return !isMfaEnforceable({ profile, mfaEnforced, authMethod }) || hasStrongMfaDevice(devices)
}

export function deviceTypesDisplayOrder(deviceType: DeviceType): number {
  return ['WEBAUTHN', 'GOOGLE', 'EMAIL', 'SMS'].indexOf(deviceType)
}
