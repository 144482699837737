/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { stringify } from 'query-string'
import { useLocation } from 'react-router-dom'
import React, { useEffect } from 'react'

import history from '@modules/utils/history'
import { useConfig } from '@modules/cui/ConfigContext'
import Feature from '@modules/utils/feature'

import { useFlagsWhenLoaded } from '../launchdarkly'
import { ResourceType } from '../discovery-questions-lib/utils'
import {
  isServerlessOnboardingToken,
  isStatefulOnboardingToken,
} from '../discovery-questions-lib/steps'
// eslint-disable-next-line import/no-restricted-paths
import UserConsoleAppLoadingRoot from '../../public/components/AppLoadingRoot/UserConsoleAppLoadingRoot'

import type { FunctionComponent } from 'react'

export function handleOnboardingRedirect(
  onboardingToken: string | null | undefined,
  onboardingResourceFlag: ResourceType,
  referrer?: string | null,
) {
  const isServerless = isServerlessOnboardingToken(onboardingToken)
  const isStateful = isStatefulOnboardingToken(onboardingToken)

  let queryString = stringify({ onboarding_token: onboardingToken, referrer })

  if (queryString.length > 0) {
    queryString = `?${queryString}`
  }

  if ((isServerless && isStateful) || (!isServerless && !isStateful)) {
    const path =
      onboardingResourceFlag === ResourceType.Serverless
        ? '/onboarding/serverless'
        : '/onboarding/stateful'

    history.push(`${path}${queryString}`)
  } else if (isServerless) {
    history.push(`/onboarding/serverless${queryString}`)
  } else {
    history.push(`/onboarding/stateful${queryString}`)
  }
}

const RedirectOnboarding: FunctionComponent = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const onboardingToken = queryParams.get('onboarding_token')
  const referrer = queryParams.get('referrer')
  const [isFlagUsable, flags] = useFlagsWhenLoaded()
  const isServerlessFeatureEnabled = useConfig(Feature.serverless)

  useEffect(() => {
    if (!isServerlessFeatureEnabled) {
      handleOnboardingRedirect(onboardingToken, ResourceType.Stateful)
    }

    if (isFlagUsable) {
      handleOnboardingRedirect(onboardingToken, flags.onboardingResource, referrer)
    }
  }, [onboardingToken, isFlagUsable, flags, referrer])

  return <UserConsoleAppLoadingRoot />
}

export { RedirectOnboarding }
